.install_container {
  background: #ffffff;
  .install_inner {
    padding: 145px 165px 0;
    .install_header {
      margin-bottom: 64px;
      h1 {
        font-weight: 800;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #111827;
      }
    }
    .install_content {
      display: flex;
      justify-content: center;
      gap: 34px;

      .install_details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          margin-bottom: 2px;
        }
      }

      .install_steps {
        margin: 0 16px;
        background: #d7f4fc;
        width: 629px;
        height: fit-content;

        .step {
          display: flex;
          align-items: center;

          padding: 54px 80px 41px;

          .step_details {
            margin-left: 80px;
            .step_heading {
              h1 {
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #18191f;
              }
            }

            .step_info {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #18191f;
              width: 299px;
              margin-top: 4px;
            }
          }
        }

        .active {
          display: flex;
          align-items: center;

          padding: 54px 80px 41px;
          background: #35c9ef;
          position: relative;

          .step_details {
            margin-left: 80px;

            .step_heading {
              h1 {
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #ffffff;
              }
            }

            .step_info {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              width: 299px;
              margin-top: 4px;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            left: -18px;
          }
        }
      }
    }
  }
}
