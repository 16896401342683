.faq_container {
  background: url('../../../utils/images/FAQ.png');
  height: auto;
  padding-bottom: 58px;
  background-repeat: no-repeat;
  background-size: cover;

  .faq_inner {
    padding: 0 165px;
    .logo {
      padding: 114px 0 0px;
      text-align: center;
    }

    .header {
      margin-top: 11px;
      margin-bottom: 107px;
      h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 65px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #111827;
      }
    }
  }
}
