.install_container {
  background: #ffffff;
  .install_inner {
    .install_header {
      padding: 50px 0 48px;
      h1 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.025em;
        font-feature-settings: 'liga' off;
        color: #111827;
      }
    }

    .install_details {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }

    .install_steps {
      margin: 0 16px;
      background: #d7f4fc;

      .step {
        display: flex;
        align-items: center;

        padding: 30px 16px;
        height: 130px;

        .step_details {
          margin-left: 17px;
          .step_heading {
            h1 {
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: #18191f;
            }
          }

          .step_info {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #18191f;

            margin-top: 4px;
          }
        }
      }

      .active {
        display: flex;
        align-items: center;

        padding: 30px 16px;
        height: 130px;
        background: #35c9ef;
        position: relative;

        .step_details {
          margin-left: 17px;

          .step_heading {
            h1 {
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: #ffffff;
            }
          }

          .step_info {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;

            margin-top: 4px;
          }
        }

        .arrow {
          position: absolute;
          top: -10%;
          left: 50%;
        }
      }
    }
  }
}
