.slider_card {
  width: 328px !important;
  height:217px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1),
    0px 24px 60px rgba(6, 47, 125, 0.05), 0px 12px 24px rgba(27, 59, 119, 0.05);
  border-radius: 4px;
  margin-bottom: 48px;

  .slider_card_inner {
    padding: 24px 20px;

    .name_container {
      display: flex;
      .name_inner {
        margin-left: 12px;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #111827;
        }

        img {
          margin-top: 4px;
        }
      }
    }

    .title {
      margin-top: 21px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #111827;
    }
    .comment {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      margin-top: 4px;
    }
  }
}
