.about_container {
  background: rgba(242, 247, 255, 0.7);
  margin-top: 30px;
  height: 615px;

  .about_inner {
    .about_heading {
      h1 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.025em;
        font-feature-settings: 'liga' off;
        color: #111827;
        padding: 50px 0 16px;
      }
    }

    .about_details {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      font-feature-settings: 'liga' off;
      color: #111827;
      margin: 0 9px 22px 13px;
    }
  }
}
