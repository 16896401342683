.review_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:518px;

  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #111827;
    margin: 10px 0 26px;
  }

  .review_details_card {
    width: 328px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1),
      0px 24px 60px rgba(6, 47, 125, 0.05),
      0px 12px 24px rgba(27, 59, 119, 0.05);
    border-radius: 4px;
    align-self: center;
    margin-bottom: 16px;

    .review_inner {
      display: flex;
      justify-content: space-between;
      padding: 16px 17px;

      .rating_details {
        display: flex;
        flex-direction: column;

        .ratings {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          font-feature-settings: 'liga' off;
          color: #111827;
        }

        .ratings_num {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          font-feature-settings: 'liga' off;
          color: #111827;
          margin: 8px 0 12px;
        }

        p {
          font-weight: 300;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #595f68;
          margin-top: 7.2px;

          span {
            font-weight: bold;
            border-bottom: 1px solid #595f68;
            padding-bottom: 1px;
          }
        }
      }
    }
  }
}
