.banner_container {
  img {
    position: absolute;
    z-index: -1;
    width: 100%;
  }

  .banner_inner {
    display: flex;
    justify-content: space-between;
    padding: 159px 165px 0;

    .details {
      display: flex;
      flex-direction: column;
      max-width: 534px;

      .heading {
        font-weight: 800;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -0.025em;
        color: #111827;
      }

      .sub_heading {
        font-weight: 650;
        font-size: 36px;
        line-height: 60px;
        color: #111827;
      }

      .description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #374151;
        margin-top: 36px;
      }

      form {
        display: flex;
        margin-top: 44px;
        justify-content: space-between;
        column-gap: 10px;

        input {
          border: 2px solid #c5c7cc;
          border-radius: 8px;
          width: 200px;
          height: 56px;
          padding: 15px 0 15px 16px;
          flex: 1.7;
        }

        button {
          outline: none;
          border: none;
          background: #1bb3da;
          border-radius: 8px;

          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          flex: 2;
        }
      }

      .metrics {
        display: flex;
        margin-top: 44px;
        justify-content: space-between;

        max-width: 400px;
        width: 320px;
        column-gap: 55px;
        display: flex;
        justify-content: space-between;

        .cashback,
        .offers {
          border-left: 2px solid #35c9ef;
          color: #111827;
          display: flex;
          flex-direction: column;
          padding-left: 16px;

          span:first-child {
            font-weight: 700 !important;
            font-size: 24px !important;
            line-height: 32px !important;
          }

          span:last-child {
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 20px !important;
            white-space: nowrap;
          }
        }
      }
    }

    .mobile {
      min-width: 320px;
      img {
        position: relative;
        left: 20px;

        width: 100%;
        height: 100%;
      }
    }
  }
}
