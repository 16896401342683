.footer_container {
  background: #002a35;
  .footer_inner {
    padding: 0 165px 52px;
    display: flex;
    justify-content: space-between;
    padding-top: 56px;

    .footer_content {
      .footer_logo {
        margin-bottom: 42px;
      }

      .footer_items {
        list-style: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #d9dbe1;
        margin-bottom: 42px;

        li:first-of-type {
          display: block;
        }

        li {
          display: inline-flex;
          margin: 0 35px 12px 0;
        }
      }
      .footer_copyrights {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d9dbe1;
        margin-top: 47px;
      }
    }
    .link_group {
      .footer_links {
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          color: #d9dbe1;
        }

        .links {
          margin-top: 16px;
          a {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
