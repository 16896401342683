.review_container {
  display: flex;
  flex-direction: column;
  height: 950px;

  .review_inner {
    padding: 100px 165px 0;
    h1 {
      font-weight: 800;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.025em;
      color: #111827;
      margin-bottom: 89px;
    }

    .review_details_card {
      background: #ffffff;
      margin-bottom: 84px;

      .review_inner {
        display: flex;
        padding: 16px 17px;

        .rating_details {
          display: flex;
          flex-direction: column;
          padding-right: 41px;
          border-right: 1px solid rgba(0, 0, 0, 0.12);

          .ratings {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #111827;
          }

          .ratings_num {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #111827;
            margin: 8px 0 12px;
          }

          p {
            font-weight: 300;
            font-size: 12px;
            line-height: 19px;
            color: #595f68;
            text-align: center;
            margin-top: 7.2px;

            span {
              font-weight: bold;
              border-bottom: 1px solid #595f68;
              padding-bottom: 1px;
              font-size: 16px;
            }
          }
        }

        .rating_bars {
          padding-left: 62px;
        }
      }
    }
  }
}
