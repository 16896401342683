.modal_container {
  background: #fff;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;

  width: 100%;
  height: 100%;

  .modal_inner {
    padding: 19px 27px 0px 20px;

    .modal_header {
      border-bottom: 1px solid #edeff1;
      padding-bottom: 22px;
      display: flex;
      justify-content: space-between;
    }

    .modal_items {
      list-style: none;

      li {
        border-bottom: 1px solid #edeff1;
        padding: 20px 0;

        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.025em;
        color: #111827;
      }
    }
  }
}
