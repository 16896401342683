.footer_container {
  background: #002a35;
  .footer_inner {
    padding: 42px 16px 28px 16px;
    .footer_logo {
      margin-bottom: 42px;
    }

    .footer_items {
      list-style: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #d9dbe1;
      width: 300px;
      margin-bottom: 42px;

      li {
        display: inline-flex;
        margin: 0 35px 12px 0;
      }
    }

    .footer_links {
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #d9dbe1;
      }

      .links {
        margin-top: 16px;
        a {
          margin-right: 15px;
        }
      }
    }

    .footer_copyrights {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #d9dbe1;
      margin-top: 47px;
    }
  }
}
