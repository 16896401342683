.accordion {
  margin: 40px 16px 0px;

  .accordion-item {
    border-color: #fff;
    border-bottom: 1px solid #c5c7cc;

    &:first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      .accordion-button {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom: none;

      .accordion-button {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    .accordion-button {
      outline: none;
      border-color: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #111827;
      padding: 32px 17px 12px 16px;

      &:focus {
        box-shadow: none;
        border-color: none;
      }

      &:not(.collapsed) {
        color: black;
        background-color: #fff;
        box-shadow: none;
      }

      &::before {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        content: '';
        background: url('../images/expand.png');
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 20px;
      }

      &:not(.collapsed)::before {
        background: url('../images/minus.png');
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 20px;
        transition: background 0.2s ease-in-out;
      }

      &::after {
        display: none;
      }

      @media (min-width: 990px) {
        font-weight: 600;
        font-size: 20px;
        line-height: 56px;
        color: #111827;

        &::before {
          flex-shrink: 0;
          width: 1.35rem;
          height: 1.35rem;
          content: '';
          background: url('../images/dk_expand.png');
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px;
        }

        &:not(.collapsed)::before {
          background: url('../images/dk_minus.png');
          flex-shrink: 0;
          width: 1.35rem;
          height: 1.35rem;
          content: '';
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 20px;
          transition: background 0.2s ease-in-out;
        }

        &::after {
          display: none;
        }
      }
    }

    .accordion-body {
      padding: 10px 16px 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #374151;
      opacity: 0.8;
      white-space: pre-line;

      @media (min-width: 990px) {
        padding: 0px 96px 63px 54px;
        font-weight: 500;
        font-size: 16px;
        line-height: 200%;
        color: #374151;
        opacity: 0.8;
      }
    }
  }
}

.slick-slider {
  .slick-list {
    min-height: 300px;
    .slick-track {
      .slick-slide {
        padding-top: 10px;
        @media (max-width: 990px) {
          display: flex !important;
          justify-content: center !important;
        }
      }
    }
  }

  .slick-dots {
    pointer-events: none;
    bottom: 0px;
    .slick-active {
      button {
        &::before {
          color: #35c9ef;
          opacity: 1;
        }
      }
    }

    li {
      margin: 0;
      button {
        padding: 0;
        &::before {
          color: #f1f1f1;
          opacity: 1;
          font-size: 11px;
        }
      }
    }

    @media (min-width: 990px) {
      bottom: -80px;

      li {
        margin: 0;
        button {
          padding: 0;
          &::before {
            color: #f1f1f1;
            opacity: 1;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.about_slider_pics {
  .slick-slider {
    pointer-events: none;
    .slick-list {
      min-height: 300px;
      .slick-track {
        .slick-slide {
          padding-top: 10px;
          display: flex !important;
          justify-content: center !important;
        }
      }
    }

    .slick-dots {
      pointer-events: none;
      bottom: -17px;
      .slick-active {
        button {
          &::before {
            color: #35c9ef;
            opacity: 1;
          }
        }
      }

      li {
        margin: 0;
        button {
          padding: 0;
          &::before {
            color: #f1f1f1;
            opacity: 1;
            font-size: 11px;
          }
        }
      }

      @media (min-width: 990px) {
        bottom: -80px;

        li {
          margin: 0;
          button {
            padding: 0;
            &::before {
              color: #f1f1f1;
              opacity: 1;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.about_slider {
  margin: 0 16px 40px;

  &::-webkit-scrollbar {
    display: none;
  }
  .slick-slider {
    pointer-events: none;
    .slick-list {
      min-height: 45px;
      .slick-track {
        display: flex;
        justify-content: center;

        .slick-slide {
          .first_header,
          .second_header,
          .third_header {
            max-width: max-content;
            height: 27px;
            background: #ffffff;
            border: 1px solid #6d8697;
            border-radius: 8px;
            text-align: center;

            span {
              font-weight: 600;
              font-size: 11px;
              line-height: 15px;
              color: #6d8697;
              padding: 0px 8px;
            }

            @media (max-width: 370px) {
              span {
                font-size: 11px;
              }
            }
          }

          .third_header {
            span {
              padding: 6px;
            }
          }
        }

        .slick-current {
          .first_header,
          .second_header,
          .third_header {
            background: #35c9ef;
            border: none;

            position: relative;
            span {
              color: #ffffff;
            }

            &::after {
              content: '';
              width: 0;
              height: 0;

              position: absolute;
              top: 26px;
              left: 45%;

              border-top: 0.5rem solid #35c9ef;
              border-right: 0.5rem solid transparent;
              border-bottom: 0.5rem solid transparent;
              border-left: 0.5rem solid transparent;
            }
          }
        }
      }
    }
  }

  @media (min-width: 990px) {
    margin-bottom: 68px;

    .slick-slider {
      .slick-list {
        min-height: 84px;
        .slick-track {
          .slick-slide {
            width: max-content !important;
            margin-left: 48px;

            .first_header,
            .second_header,
            .third_header {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 17px;
              width: 239px;
              height: 58px;
              border: 1px solid #2e526b;
              border-radius: 20px;
              background: transparent;
              margin: 0;

              span {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #2e526b;
              }
            }
          }

          .slick-slide:last-of-type {
            margin-right: 48px;
          }

          .slick-current {
            .first_header,
            .second_header,
            .third_header {
              background: #35c9ef;
              border: none;

              position: relative;
              span {
                color: #ffffff;
              }

              &::after {
                content: '';
                width: 0;
                height: 0;

                position: absolute;
                top: 58px;
                left: 45%;

                border-top: 0.6rem solid #35c9ef;
                border-right: 0.6rem solid transparent;
                border-bottom: 0.6rem solid transparent;
                border-left: 0.6rem solid transparent;
              }
            }
          }
        }
      }
    }
  }
}
