.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;

  .logo {
    display: flex;
  }

  nav {
    display: flex;
    justify-content: space-between;
    margin: 16px 5px 0 16px;

  }
}
