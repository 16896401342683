.withdraw_container {
  background-color: #ffffff;
  padding-bottom: 64px;

  .header {
    padding: 50px 0px 0;
    width: 258px;
    margin: auto;

    h1 {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.025em;
      color: #111827;
    }
  }

  .payment {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 36px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: #18191f;
      }
    }
  }
}
