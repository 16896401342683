@import '../src/utils/sass/app.scss';


html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
p,
span,
section,
ul,
ol,
li,
h1,
h2,
h3,
h4,
form {
  margin: 0;
  padding: 0;
}
