.withdraw_container {
  background-color: #ffffff;
  .withdraw_inner {
    padding: 0 165px 145px;

    .header {
      padding: 131px 0px 0;
      margin: auto;

      h1 {
        font-weight: 800;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #111827;
      }
    }

    .payment {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 82px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 28px;
          line-height: 32px;
          text-align: center;
          color: #18191f;
        }
      }

      div:first-of-type {
        border-right: 1px solid #c5c7cc;
        img {
          margin-right: 131px;
        }

        span {
          display: inline-block;
          margin-right: 131px;
        }
      }

      div:last-of-type {
        img {
          margin-left: 104px;
        }
        span {
          margin-left: 104px;
        }
      }
    }
  }
}
