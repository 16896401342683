.about_container {
  background: rgba(242, 247, 255, 0.7);
  height: 1131px;

  .about_inner {
    padding: 0 165px;
    .about_heading {
      h1 {
        font-weight: 800;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #111827;
        padding: 109px 0 16px;
      }
    }

    .about_details {
      width: 803px;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      font-feature-settings: 'liga' off;
      color: #111827;
      margin: 0 auto 55px;
    }
  }
}
