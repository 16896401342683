.refer_container {
  background: #35c9ef;

  .refer_inner {
    .heading {
      padding: 40px 43px 12px 16px;
      h1 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.025em;
        color: #ffffff;
      }
    }

    .details {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      margin: 0 24px 32px;
    }

    .link {
      margin: 0 0 51px 16px;
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        margin-right: 14px;
      }
    }

    .images {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        display: inline-block;
      }
    }
  }
}
