.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  padding: 30px 165px 0;

  nav {
    display: flex;
    justify-content: space-between;
    .logo {
      display: flex;
      margin-top: 4px;
      cursor: pointer;
    }
    .items {
      list-style: none;
      display: flex;

      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #111827;

      li {
        cursor: pointer;
        margin-left: 24px;
      }
    }
  }

}
