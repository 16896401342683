.faq_container {
  background: url('../../../utils/images/mob_faq.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-bottom: 58px;

  .faq_inner {
    .logo {
      padding: 50px 156px 0;
      text-align: center;
    }

    .header {
      margin-top: 8px;
      h1 {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #111827;
      }
    }
  }
}
