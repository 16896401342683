.hero_container {
  width: 100%;
  max-width: 100%;
  min-height: 778.41px;

  .main_bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 778.41px;
  }

  .hero_inner {
    display: flex;
    padding: 91px 27px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      color: #111827;
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      letter-spacing: -0.025em;
      line-height: 45px;
    }

    .sub_header {
      display: inline-block;
      font-weight: 700;
      font-size: 23px;
      text-align: center;
      line-height: 45px;
      letter-spacing: -0.025em;
      color: #111827;

      @media(min-width:370px){
          font-size: 24px;
      }
    }

    button {
      background-color: #1bb3da;
      outline: none;
      border: none;
      border-radius: 8px;
      color: #fff;
      width: 328px;
      height: 56px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      @media (max-width: 360px) {
        width: 100%;
      }
    }

    .metrics {
      max-width: 400px;
      width: 320px;
      column-gap: 55px;
      padding: 0 17px 0 12px;
      display: flex;
      justify-content: space-between;

      .cashback,
      .offers {
        border-left: 2px solid #35c9ef;
        color: #111827;
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        span:first-child {
          font-weight: 700 !important;
          font-size: 24px !important;
          line-height: 32px !important;
        }

        span:last-child {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          white-space: nowrap;
        }
      }
    }
  }
}
