.refer_container {
  background: #35c9ef;

  .refer_inner {
    padding: 0 165px;
    display: flex;
    .refer_content {
      .heading {
        padding: 68px 0 0;
        h1 {
          font-weight: 800;
          font-size: 48px;
          line-height: 64px;
          color: #ffffff;
        }
      }

      .details {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #ffffff;
        margin-top: 8px;
      }

      .link {
        margin-top: 48px;
        margin-bottom: 39px;
        span:first-of-type {
          display: block;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          margin-bottom: 20px;
        }

        span:last-of-type {
          margin: 0 24px;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          margin-bottom: 20px;
        }

        div {
          text-decoration: none;
          display: inline;
          box-shadow: 10px 15px 30px rgb(41 41 42 / 50%);
          button {
            outline: none;
            border: none;
            padding: 16px;
            width: 220px;
            background: #ffffff;
            border-radius: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #35c9ef;
          }
        }
      }
    }

    .images {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img:first-of-type {
        align-self: flex-start;
      }
    }
  }
}
