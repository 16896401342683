.floater_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.03);
  border-radius: 20px 20px 0px 0px;

  position: fixed;
  bottom: 0;
  a {
    text-decoration: none;
    button {
      background-color: #1bb3da;
      outline: none;
      border: none;
      border-radius: 8px;
      color: #fff;
      width: 328px;
      height: 56px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      @media (max-width: 360px) {
        width: 100%;
      }
    }
  }
}
